.mixer-room{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.mixer-button{
    position: absolute;
    border: none;
    padding: 5px;
    border-radius: 10px;
    right: 20px;
    pointer-events: all;
}

.mixer-button:hover{
    background-color: rgb(197, 197, 197);
    pointer-events: all;
}

.mixer-room-create{
    top: 330px;
}

.mixer-room-remove{
    top: 375px;
}

.join-mixer-room{
    top: 400px;
}

.start-mixer-room{
    top: 490px;
}

.mixer-room-stop{
    top: 445px;
}

.mixer-notification{
    position: absolute;
    width: 200px;

    padding-top: 10px;
    padding-bottom: 10px;

    border-radius: 10px;

    background-color: white;

    text-align: center;

    font-weight: bold;
    font-size: small;

    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.host-control-notification{
    position: absolute;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 10px;

    background-color: rgba(255, 255, 255, 0.377);

    text-align: center;

    font-weight: bold;
    font-size: 10px;

    top: 180px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.host-control-notification-info{
    position: absolute;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 10px;

    background-color: rgba(157, 255, 0, 0.377);

    text-align: center;

    font-weight: bold;
    font-size: 10px;

    top: 225px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.host-control-create{
    top: 370px;
}

.host-control-stop{
    top: 400px;
}