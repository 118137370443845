.nft-popup-base{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.514);
    z-index: 10;
}

.nft-popup-image{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: auto;
    transform: translate(-50%, -50%);
}

.nft-popup-close{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
}