.endles-run-button-left {
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 20vh;
    height: 20vh;

    border-radius: 50%;
    border: none;

    font-size: 10vh;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);

    transform: translateX(-50%);
}

.endles-run-button-right {
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 20vh;
    height: 20vh;

    border-radius: 50%;
    border: none;

    font-size: 10vh;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    
    transform: translateX(-50%);
}

.endles-run-button-left:hover, .endles-run-button-right:hover{
    background-color: rgba(41, 41, 41, 0.575);
}

.endless-run-state {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    max-width: 400px;
    width: 90%;

    color: white;
    text-shadow: 2px 2px #000000;
    font-weight: bolder;
    font-size: 20px;

}

.endless-run-state-base{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    width: 100%;
    height: 100%;

    background-color:  rgba(0, 0, 0, 0.795);
}

.endless-run-play-score-base{
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    
    color: white;
    text-shadow: 2px 2px #000000;
}

.endless-run-play-score-title{
    font-size: 4vh;
    line-height: 1px;
}

.endless-run-play-score{
    font-size: 8vh;
    font-weight: bolder;
}

.endless-run-play-result-base{
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    
    color: white;
    text-shadow: 2px 2px #000000;
    
    text-align: center;

}

.endless-run-retry-button{
    position: relative;

    padding: 15px;
    margin-left: 10px;
    margin-top: 20px;

    border-radius: 15px;
    border: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.575);
    border-width: 1px;
    color: white;
}

.endless-run-retry-button:hover{
    background-color: rgba(41, 41, 41, 0.575);
}