.back-to-main-scene {
  position: absolute;
  top: 8px;
  right: 70px;
  /* width: 45px; */
  height: 50px;
  border-radius: 30px;
  /* font-weight: bolder; */
  color: #fff;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}
.back-to-main-scene span {
  margin: 10px;
}

@media only screen and (max-device-width: 425px) {
  .back-to-main-scene {
    top: 66px;
    right: 10px;
  }
}
