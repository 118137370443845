.minimap-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* position: absolute; */
    top: 0px;
    left: 0px;
}

.minimap-panel {
    /* position: absolute; */
    /* left: 50%; */
    /* top: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: white;
    width: 100%;
    /* max-width: 600px; */

}

.minimap-image {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
}

.minimap-image-base{
    width: 100%;
    background-color: black;
}

.minimap-pin{
    position: absolute;
    width: 50px;
    top: 0px;
    left: 0px;

    transform: translate(-50%, -100%);
    animation-name: minimap-pin-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    pointer-events: none;
}

@keyframes minimap-pin-animation{
    0%{
        opacity: 1;
    }
    40%{
        opacity: 0.5;
    }
    60%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

#minimap-position {
    position: absolute;
    top: 0px;
    left: 0px;

    pointer-events: none;
}

.minimap-button {
    margin-bottom: 5px;
    width: 100%;
    border: none;
    border-radius: 7px;
    padding: 8px;
    background-color: gradient();
}

.minimap-button:hover {
    background-color: antiquewhite;
}

.minimap-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
}

.minimap-button-base {
    /* padding-top: 50px; */
}
.minimap-button-base svg {
    max-width:40px;
    height: auto;
}

.minimap-confirmation{
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border-radius: 10px;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .minimap-panel {
        /* width: 90%; */
    }

    .minimap-button-base {
        padding-top: 5px;
    }
}
