.player {
    background-color: rgb(238, 238, 238);
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);
}

.button:hover {
    color: rgb(78, 78, 78);
}

.slider {
    position: absolute;
    /* left: 50px; */
    top: 50%;
    max-width: 100%;
    height: 10px;
    width: 100%;
    background: #383838;

    transform: translateY(-50%);
    display: block;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #e9e9e9;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #ffffff;
    cursor: pointer;
}

.timeText{
    position: absolute;
    right: 18px;
    top: 49%;

    transform: translateY(-50%);
}


@media only screen and (max-width: 460px) {
    .slider{
        display: none;
    }
}