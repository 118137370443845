.animation-control{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 65px;
    left: 15px;
    border-radius: 20px;
    color: white;
    padding: 10px;
    width: 200px;
    padding-bottom: 5px;
    z-index: 1;
}

.title{
    padding-bottom: 10px;
    font-size: 15px;
}

.panel{
    background-color: rgba(0, 0, 0, 0.418);
    padding: 10px;
    border-radius: 50px;
    font-size: 12px;
    margin-bottom: 20px;
}

.animation-button{
    border-radius: 50px;
    border: none;
    margin-bottom: 10px;
    height: 30px;
    font-size: 13px;
}

@media only screen and (max-width: 460px) {
}