.hosted-room{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.hosted-room-button{
    border: none;
    border-radius: 10px;
    padding: 5px;
    position: absolute;
    right: 20px;
    top: 250px;
    background-color: white;
    pointer-events: all;
}

.hosted-room-button:hover{
    background-color: rgb(230, 230, 230);
}

.hosted-room-join{
    background-color: white;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 290px;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
}

.hosted-room-menu-input{
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    border: none;
    border-radius: 10px;
    background-color: rgb(226, 226, 226);
    pointer-events: all;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.hosted-room-menu-join-button{
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    pointer-events: all;
    background-color: rgb(0, 212, 85);
    font-size: small;
    font-weight: bold;
    color: white;
}

.hosted-room-menu-join-button:hover{
    background-color: rgb(77, 252, 147);
}