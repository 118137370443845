.change-scene-base{
    position: absolute;
    left: 80px;
    top: 20px;
    /* width: 100px; */
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
    z-index: 1;
}
.change-scene-base.circular{ 
    right: 135px;
    top: 50px;
    left: auto;
}

.change-scene-button{
    display: block;
    margin-bottom: 5px;
    height: 30px;
    width: 100px;
    pointer-events: all;
    
    border-radius: 10px;
    border: none;
}

.change-scene-toggle{
    position: absolute;
    
    top: 130px;
    left: 20px;
    height: 30px;
    pointer-events: all;

    border-radius: 10px;
    border: none;
    
    background-color: rgb(255, 255, 255);
}

.change-scene-toggle:hover, .change-scene-button:hover{
    background-color: rgb(235, 235, 235);
}

.change-scene{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}