#joystick-new {
    position: absolute;
    bottom: 90px;
    left: 13px;
    z-index: 1;
}
#joystick-new #joystick-new-base {
  position: relative;
  background: rgb(0, 0, 0, 0.4);
  width: 80px;
  height: 80px;
  z-index: 900;
  border-radius: 50%;
  pointer-events: none;
}

#joystick-new #joystick-new-pointer {
  position: absolute;
  background: rgb(255, 255, 255, 1);
  width: 30px;
  height: 30px;
  z-index: 1001;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  pointer-events: none;
}

#joystick-new #joystick-new-jump-button {
  position: relative;
  background: rgb(0, 0, 0, 0.4);
  width: 80px;
  height: 80px;
  z-index: 1000;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
}

#joystick-new #joystick-new-jump-text {
  text-align: center;
  position: relative;
  color: rgba(255, 255, 255, 0.4);
}

#joystick-new-jump-button {
  position: absolute;
  background: rgb(0, 0, 0, 0.4);
  width: 80px;
  height: 80px;
  z-index: 999;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  right: 13px;
  bottom: 175px;
}

#joystick-new-jump-button-default {
  position: absolute;
  background: rgb(0, 0, 0, 0.4);
  width: 80px;
  height: 80px;
  z-index: 999;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  right: 13px;
  bottom: 90px;
  z-index: 1;
}

#joystick-new-down-button {
  position: absolute;
  background: rgb(0, 0, 0, 0.4);
  width: 80px;
  height: 80px;
  z-index: 999;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  right: 13px;
  bottom: 90px;
}

#joystick-new-jump-button svg, #joystick-new-jump-button-default svg  {
  max-width: 35px;
}
#joystick-new-down-button svg {
  max-width: 35px;
  transform: rotate(180deg);
}

#joystick-new-jump-button #joystick-new-jump-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.4);
}