
.change-scene-counter{
    position: absolute;
    left: 50%;
    top: 15%;
    background-color: rgba(0, 0, 0, 0.521);
    
    padding: 15px;
    border: none;
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
    

    color: white;
    font-weight: lighter;

    transform: translateX(-50%);
}

.change-scene-counter-border-left{
    position: absolute;
    width: 10px;
    height: 70%;
    background-color: yellow;
    left: 0px;
    top: 50%;

    border-radius: 0px 5px 5px 0px;

    transform: translateY(-50%);
}

.change-scene-counter-border-right{
    position: absolute;
    width: 10px;
    height: 70%;
    background-color: yellow;
    right: 0px;
    top: 50%;

    border-radius: 5px 0px 0px 5px;

    transform: translateY(-50%);

}