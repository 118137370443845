.area{
    position: absolute;
    top: 15px;
    left: 15px;
    transform: translateX(-50);
    color: rgb(0, 0, 0);
    font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: min(25px, 2vh);
    background-color: #fbd801;
    border-radius: 0px 30px 30px 30px;
    border-color: black;
    border-width: 10px;
    border: solid;
    
    font-weight: bolder;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* background-color: white; */
}