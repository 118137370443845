.popup {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.425);
  z-index: 3;
}

.category {
  font-weight: bold;
  font-size: 18px;
}

.title-text{
  font-weight: bolder;
  font-size: 25px;
  padding-top: 15px;
}

@media only screen and (max-width: 1024px) {
  /* .popup-panel {
    width: 80% !important;
  } */

  .popup-text {
    margin-top: 20px;
  }


  .popup-content {
    display: block !important;
  }

  .title-text{
    padding-bottom: 45px;
  }
}


@media only screen and (max-width: 1200px) {
  /* .popup-panel {
    width: 80% !important;
  } */

  .title-text{
    padding-bottom: 45px;
  }
}

.popup-content {
  height: 100%;
  display: block !important;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.popup-panel {
  position: relative;
  /* max-width: 1000px; */
  /* max-height: 80%; */
  /* height: 800px; */
  height: 100%;
  width: 100%;
  /* top: 50%; */
  /* left: 50%; */
  background-color: rgb(255, 255, 255);
  /* border-radius: 10px; */
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  z-index: 1;

  /* transform: translate(-50%, -50%); */
}

.popup-title {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  /* font-weight: bold; */
  /* font-size: 30px; */
  /* padding-left: 35px; */
  /* padding-right: 150px; */
  /* height: auto; */
  border-bottom: 1px solid #7a7a7a;
  /* transform: translateX(-50%); */
}

.popup-text {
  text-align: justify;
  display: inline-block;
  /* padding-top: 5px; */
  padding-left: 10px;
  font-size: large;
  /* transform: translateX(-50%); */
}

.popup-description {
  overflow-y: hidden;
  padding-right: 0;
  height: 100%;
  margin-top: 10px;
}

.popup-image {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popup-audio {
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 10px;
}

.popup-close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  width: 50px;
  height: 50px;
  background-color: red;
  color: white;
  border-radius: 50%;
  transform: scale(50%, 50%);
  font-size: 30px;
}

.popup-close:hover {
  background-color: rgb(167, 3, 3);
}

.text-description {
  width: 100%;
}

.text-base {
  position: relative;
  height: 450px;
  padding-right: 10px;
  overflow-y: auto;
  top: 0px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
  .text-base {
    height: 100%;
    overflow-y: hidden;
  }

  .popup-panel-scroll {
    height: 100%;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .title-text{
    padding-bottom: 45px;
  }
}

.audio-player {
  position: relative;
  left: 50%;
  width: 80%;
  height: 40px;
  max-width: 250px;

  transform: translateX(-50%);
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-base::-webkit-scrollbar-corner,
.popup-panel-scroll::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.text-base::-webkit-scrollbar,
.popup-panel-scroll::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

.text-base::-webkit-scrollbar-track,
.popup-panel-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.text-base::-webkit-scrollbar-thumb,
.popup-panel-scroll::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}

.text-base::-webkit-scrollbar-thumb:hover,
.popup-panel-scroll::-webkit-scrollbar-thumb:hover {
  background: #f09819;
}

.image-display {
  background-color: rgb(235, 235, 235);
}

.image-label {
  font-weight: bold;
  font-size: small;
}

.image-description {
  font-weight: normal;
  font-size: smaller;
}

.select-language {
  /* position: absolute; */
  /* right: 55px; */
  /* top: 30px; */
  width: auto;
  border: none;
  background-color: #e2e2e2;
  padding: 5px;
  padding-left: 15px;
  border-radius: 15px;
  float: right;
  /* margin-right: 30px; */
}

.select-language:focus {
  border: none;
  outline: none;
}

.popup-header-text{
  position: absolute;
  right: 0px;
  bottom: 20px;
}

.carausel-caption-text{
  padding-top: 0px;
  padding-bottom: 5px;
}

.popup-panel-iframe{
  height: 100vh;
  max-height: 600px;
  padding-top: 30px;
  padding-bottom: 30px;
}