.conversation-space{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.514);
    color: white;
    top: 100px;
    left: 50%;

    transform: translateX(-50%);

    padding: 10px 20px 10px 20px;
    border-radius: 20px;
}