.crosshair{
    position: absolute;
    width: 15px;
    height: 15px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(0, 0, 0, 0);

    border-radius: 50%;
    border-color: black;
    border-width: 10px;

    outline-style: solid;
    outline-width: 2px;
    outline-color: rgba(0, 0, 0, 0.5);

    pointer-events: none;
}

.crosshair-dot{
    position: absolute;
    width: 5px;
    height: 5px;

    top: 50%;
    left: 50%;
    transform: translate(-40%, -40%);

    background-color: rgb(0, 0, 0.5);
    
    border-radius: 50%;;
    
    pointer-events: none;
}