/* LOAD CUSTOM FONT */
@font-face {
  font-family: "Magistros";
  src: url("../../public/font/Magistros.ttf");
}

@font-face {
  font-family: "Magistros";
  src: url("../../public/font/Magistros3D.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Magistros";
  src: url("../../public/font/Magistros-LightItalic.ttf");
  font-style: italic, oblique;
}

@font-face {
  font-family: "Magistros";
  src: url("../../public/font/Magistros-Italic.ttf");
  font-weight: bold;
  font-style: italic, oblique;
}

* {
  /* font-family: "Magistros", Fallback, sans-serif; */
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;

  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.fullscreen {
  height: 100vh;
  width: 100vw;
}

#shoutout-input {
  position: absolute;
  width: 130px;
  height: 30px;
  right: 100px;
  bottom: 20px;
  border: none;
  background-color: rgb(255, 255, 255);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px 0px 0px 20px;
}

#shoutout-input:focus {
  outline: none;
}

#shoutout-button {
  position: absolute;
  width: 80px;
  height: 30px;
  right: 20px;
  bottom: 20px;
  border: none;
  border-radius: 0px 20px 20px 0px;
  color: white;
  font-weight: bolder;
  background-color: rgb(122, 219, 122);
}

#shoutout-button:hover {
  background-color: rgb(101, 194, 101);
}

.loading-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.loading {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: sans-serif;
  text-align: center;
  /* background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%); */
  background: #000;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  top: 0;
  /* font-weight: bold; */
  flex-direction: column;
  z-index: 9999999999;
  background-size: cover;
}

.row {
  margin: 0 !important;
}

.hide {
  transition: 1s ease-in;
  opacity: 0;
  top: -100%;
}

.centered-input {
  width: 100%;
  text-align: center;
}

.audio-toggle {
  position: absolute;
  left: 10px;
  bottom: 100px;
  left: 20px;
  top: 100px;
  height: 30px;
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
}

.audio-toggle:hover {
  background-color: #cccccc;
}

#audio-denied-alert {
  position: absolute;
  left: 10px;
  padding: 2px;
  left: 20px;
  top: 100px;
}

#player-list-table {
  /* position: absolute; */
  /* top: 140px; */
  /* left: 20px; */
  color: #fff;
  font-size: 14px;
  width: 100%;
  vertical-align: middle;
}

#avatar-selection-button {
  position: absolute;
  right: 20px;
  top: 220px;
  border: none;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
}

#avatar-selection-button:hover {
  background-color: #cccccc;
}

.button-show-setting {
  position: absolute;
  top: 305px;
  right: 20px;

  border: none;
  border-radius: 10px;

  background-color: white;
  color: black;

  padding: 5px;
}

.button-show-setting:hover {
  background-color: rgb(212, 212, 212);
}

.button-share-screen:hover {
  background-color: rgb(212, 212, 212);
}

.button-share-screen {
  position: absolute;
  top: 290px;
  right: 20px;

  border: none;
  border-radius: 10px;

  background-color: white;
  color: black;

  padding: 5px;
}

.button-share-screen:disabled {
  opacity: 0.6;
  color: rgb(255, 255, 255);
  background-color: rgb(138, 138, 138);
}

.button-login {
  position: absolute;
  top: 490px;
  right: 20px;
}

.btn-group-interact {
  position: absolute;
  bottom: 10px;
  left: 15px;
  z-index: 1;
}

.btn-group-interact.right {
  right: 15px;

  left: auto;
}

.btn-group-interact button {
  padding: 10px;
  width: 50px;
  height: 50px;
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50% !important;
}

.btn-group-interact button.chat {
  /* width: 100px; */
  width: auto;
  border-radius: 30px !important;
  font-size: 18px;
}

.btn-group-interact button.chat span {
  margin: 10px;
}

.btn-group-interact button.chat svg {
  margin-left: 10px;
}

.btn-group-interact button .divider {
  margin: 0 6px;
}

.btn-group-interact button svg {
  font-size: 24px;
  color: #fff;
}

/* CHAT BOX */

.chat-box {
  position: absolute;
  bottom: 70px;
  left: 15px;
  z-index: 99999998;
}

.chat-box .card {
  border: none;
  min-width: 350px;
  background: none;
}

.chat-box .card-header .btn-close {
  float: right;
}

.chat-box .chat-list {
  padding: 0;
  font-size: 0.8rem;
}

.chat-box .chat-list li {
  margin-bottom: 10px;
  overflow: auto;
  color: #000;
}

.chat-box .chat-list .in .chat-body h5 {
  text-align: left;
}

.chat-box .chat-list .out .chat-body h5 {
  text-align: right;
}

.chat-box .chat-list .chat-body h5 {
  color: #fff;
  font-size: 15px;
  margin-bottom: 5px;
}

.chat-box .chat-list .chat-body {
  color: #fff;
}

.chat-box .chat-list .chat-img {
  float: left;
  width: 48px;
  margin-top: 20px;
}

.chat-box .chat-list .chat-img img {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 100%;
}

.chat-box .chat-list .chat-message {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #5a99ee;
  /* display: inline-block; */
  padding: 5px 10px;
  position: relative;
}

.chat-box .chat-list .chat-message:before {
  content: "";
  position: absolute;
  top: 6px;
  width: 0;
  height: 0;
}

.chat-box .chat-list .chat-message h5 {
  margin: 0 0 5px 0;
  font-weight: 600;
  line-height: 100%;
  font-size: 0.9rem;
}

.chat-box .chat-list .chat-message p {
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-align: left;
}

.chat-box .chat-list .chat-body {
  margin-left: 20px;
  float: left;
  width: 70%;
}

.chat-box .chat-list .in .chat-message:before {
  left: -12px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #5a99ee;
}

.chat-box .chat-list .out .chat-img {
  float: right;
}

.chat-box .chat-list .out .chat-body {
  float: right;
  margin-right: 20px;
  text-align: right;
}

.chat-box .chat-list .out .chat-message {
  background: #fc6d4c;
}

.chat-box .chat-list .out .chat-message:before {
  right: -12px;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #fc6d4c;
}

.chat-box .card .card-header:first-child {
  -webkit-border-radius: 0.3rem 0.3rem 0 0;
  -moz-border-radius: 0.3rem 0.3rem 0 0;
  border-radius: 0.3rem 0.3rem 0 0;
}

.chat-box .card .card-header {
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  padding: 0.65rem 1rem;
  position: relative;
  font-weight: 600;
  color: #ffffff;
}

.chat-box .card .card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.5);
}

.chat-box .card .card-body {
  background: rgba(0, 0, 0, 0.5);
}

.chat-box .chat-card-body {
  overflow-y: auto;
  height: 400px;
}

.video-share-screen {
  display: none;
}

/* END CHAT BOX */

/* Landing Page */

.landing {
  background-image: url("../../public/bg-cloud.png");
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing::before {
  background: rgba(0, 0, 0, 0.25);
}

.landing .landing-image {
  width: 60%;
  margin-bottom: 50px;
}

.landing .landing-logo {
  width: 80%;
  margin-bottom: 40px;
}

.landing .account-button-list {
  margin: 15px 0;
}

.landing .account-button-list button {
  margin: 0 10px;
}

/* End Landing Page */

/* Extension */
.text-default {
  color: #ff2d55;
}

.text-default-grey {
  color: #797979;
}

.btn-default {
  background-color: #ff2d55 !important;
  border-color: #ff2d55 !important;
  color: #fff !important;
}

.btn-default:hover {
  background-color: #fff !important;
  color: #ff2d55 !important;
}

.btn-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 45, 37, 0.5) !important;
}

.btn-default-purple {
  background-color: #150536 !important;
  border-color: #150536 !important;
}

.btn-default-purple:hover {
  background-color: #fff !important;
  color: #150536 !important;
}

.btn-default-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(21, 5, 54, 0.5) !important;
}

.full-width {
  width: 100%;
}

.toast-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.form-switch .form-check-input:focus {
  border-color: #ff2d55;
  box-shadow: 0 0 0 0.25rem rgba(255, 45, 37, 0.5) !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23ff2d55%27/></svg>") !important;
}

.form-switch .form-check-input:checked {
  background-color: #ff2d55;
  border-color: #ff2d55;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.toast-content .toast-container {
  position: relative !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fade:not(.show) {
  display: none;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.backdrop-register {
  background-color: rgba(0, 0, 0, 0.9);
}

.slider {
  height: 15px;
  -webkit-appearance: none;
  background: #25282c;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 15px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #25282c;
  box-shadow: -407px 0 0 400px #ff2d55;
}

.full-height {
  height: 100%;
}

/* End Extension */

/* Mini Profile */
.mini-profile {
  position: absolute;
  top: 10px;
  left: 15px;
}

.mini-profile .card {
  border-radius: 0;
  border: none;
  border-top: 6px solid #ff2d55;
  background-color: #495057;
  color: #fff;
}

.mini-profile .connected-account {
  font-size: 14px;
  padding: 0;
  text-align: left;
}

.mini-profile .connected-account svg {
  margin-right: 10px;
  display: inline;
}

.mini-profile .player-name {
  padding: 0;
  display: flex;
  font-weight: bold;
  /* align-items: center; */
}

.mini-profile .player-setting {
  padding: 0;
  display: flex;
  align-items: center;
}

.mini-profile .player-setting button {
  max-width: 36px;
  padding: 0;
  background-color: #495057;
  border: 1px solid #666;
}

.ping-good {
  color: #9acd32;
}

.ping-bad {
  color: #0d98ba;
}

/* End Mini Profile */

/* Settings Menu */
.points-div {
  position: absolute;
  top: 90px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
  ;
}

.points-div h5 {
  font-size: 16px;
  margin-bottom: 0;
}

.points-div .points {
  min-width: 150px;
  /* height: 35px; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 20px;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  /* font-weight: bold; */
}

.settings-menu {
  position: absolute;
  background: #fff;
  /* background: #f2f2f200; */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99999999;
}

.settings-menu .nav-tabs-setting {
  background-color: #161616;
  /* display: table; */
  border-bottom: 5px solid #ff2d55 !important;
  padding-right: 38px;
}

.settings-menu .nav-tabs-setting .nav-item a {
  border: none;
  /* font-weight: bold; */
  color: #797979;
  /* margin-bottom: 8px; */
  /* background-color: #9db6c0; */
  vertical-align: middle;
}

.settings-menu .nav-tabs-setting .nav-item a span {
  display: block;
  /* margin-top: -5px; */
}

.settings-menu .nav-tabs-setting .nav-item a svg {
  width: 30px;
  height: 30px;
  /* opacity: 0.5; */
}

.settings-menu .nav-tabs-setting .nav-item a.active {
  border: none;
  color: #fff;
  background-color: inherit;
}

.settings-menu .nav-tabs-setting .nav-item a.active img {
  opacity: 1;
}

.form-setting .form-switch {
  text-align: left;
}

.close-setting {
  position: absolute;
  top: 20px;
  color: #666 !important;
  border: 1px solid #666 !important;
  right: 6px;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23797979%27><path d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/></svg>") !important;
}

#settings-menu-cover .tab-content {
  flex-grow: 1 !important;
  height: 100%;
}

#list-tab-setting-tabpane-avatar,
#list-tab-setting-tabpane-wardrobe {
  height: 100%;
}

.settings-menu-tab {
  background-color: #9db6c0;
  color: #575e78;
}

.settings-menu-tab .nav-item {
  flex: 0 0 auto !important;
}

/* End Settings Menu */

/* Avatar Menu*/
.avatar-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.avatar-menu .nav-tabs .nav-item {
  flex: 0 0 auto !important;
  border-radius: 7px;
  margin: 8px 10px 3px 10px;
  text-align: center;
}

.avatar-menu .nav-tabs {
  /* justify-content: center; */
  border-image-slice: 1;
  border: none;
  /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.35); */
  /* background-color: #f3fbff; */
}

.avatar-menu .nav-tabs .nav-item a {
  border: none;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0;
  color: #161616;
  border: none;
  /* background: #fff; */
  /* box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%); */
  border-radius: 5px;
}

.avatar-menu .nav-tabs .nav-item a svg {
  /* width: 100%; */
  width: auto;
  /* height: auto; */
  height: 30px;
  /* max-width: 40px; */
  display: block;
  margin: 0 auto;
  /* background-color: #000; */
}

.avatar-menu .nav-tabs .nav-item a span {
  font-size: 14px;
  font-weight: 500;
  /* border-bottom: 5px solid rgba(0, 0, 0, 0); */
  /* padding-bottom: 3px; */
}

.bg-outfit {
  background-color: #cecdd4;
}

.bg-outfit-dark {
  background-color: #b3b1bc;
}

.avatar-menu .nav-tabs .nav-link {
  border: 10px solid rgba(0, 0, 0, 0);
}

.avatar-menu .nav-tabs .nav-link.active {
  /* color: #ff2d55 !important; */
  /* background-color: #ff2d55 */
  background: none;
  color: #FF2D55;
  /* border: 10px solid #9db6c0; */
}

.avatar-menu .nav-tabs .nav-link.active svg {
  color: #FF2D55;
}

.avatar-menu .nav-tabs .nav-link.active span {
  /* border-bottom: 5px solid #fff; */
}

.avatar-menu .save-avatar-section {
  padding-top: 12px;
  margin-bottom: 12px;
  /* background-color: #f3fbff; */
}

.avatar-menu .cover-button-avatar-selection {
  /* margin-right: 5px; */
  padding-right: 10px;
  padding-left: 0;
  padding-bottom: 10px;
}

.avatar-menu .cover-button-avatar-selection button {
  color: #888;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);
}

.avatar-menu .cover-preview-avatar {
  /* box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%); */
  /* background: #fff; */
}

.avatar-menu .body-parts-nav {
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* End Avatar Menu */

/* Circular menu */
.hamburger {
  content: " ";
  position: relative;
  width: 20px;
  border: 2px solid #392338;
  border-radius: 5px;
  transition: all 0.333333333333333s ease;
}

.hamburger:after,
.hamburger:before {
  content: " ";
  position: absolute;
  left: 50%;
  width: 100%;
  border: 2px solid #392338;
  border-radius: 5px;
  transform: translateX(-50%);
  transition-property: top, bottom, transform;
  transition-duration: 1s, 1s, 0.25s;
}

.hamburger:after {
  top: -8px;
}

.hamburger:before {
  bottom: -8px;
}

.circular-menu {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

.circular-menu-toggle .icon {
  font-size: 30px;
}

.circular-menu-active {
  position: relative;
}

.circular-menu-toggle {
  z-index: 11;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 10px;
  transition: transform 1.3s;
  cursor: pointer;
  pointer-events: auto;
}

.circular-menu input {
  display: none;
}

.circular-menu-listings {
  z-index: 10;
  position: absolute;
  top: -220px;
  right: -220px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  transform: scale(0.1) rotate(150deg);
  transition: transform 1s;
}

.circular-menu-arrow {
  visibility: hidden;
  position: absolute;
}

.circular-menu-arrow input[type="radio"] {
  position: fixed;
  top: -99999px;
  left: -99999px;
}

.circular-menu-arrow ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.circular-menu-arrow-top {
  top: 0.5em;
  right: 220px;
}

.circular-menu-arrow-top .arrow {
  transform: rotate(-45deg);
}

.circular-menu-arrow-left {
  top: 220px;
  left: 0.5em;
}

.circular-menu-arrow-left .arrow {
  transform: rotate(-135deg);
}

.arrow {
  width: 20px;
  height: 20px;
  border-right: 6.666666666666667px solid #fff;
  border-top: 6.666666666666667px solid #fff;
  border-radius: 3px;
  transition: border-color 0.3s;
  cursor: pointer;
  pointer-events: auto;
}

.arrow:hover {
  border-color: #ff947f;
  transition: border-color 0.3s;
}

.circle {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 50%;
  transform: rotate(108deg);
  overflow: hidden;
  list-style: none;
}

.circle li {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0 100%;
  overflow: hidden;
}

.circle li .placeholder {
  position: absolute;
  left: -100%;
  padding-top: 1.5em;
  width: 200%;
  height: 200%;
  text-align: center;
  transform: skewY(54deg) rotate(18deg);
  opacity: 1 !important;
}

.circle li .placeholder .upside {
  transform: rotate(180deg);
}

.circle li .placeholder a {
  text-decoration: none;
  pointer-events: auto;
}

.circle li .placeholder button {
  pointer-events: auto;
}

.circle li .placeholder .button {
  font-size: 2.3em;
  background-color: transparent;
  border: none;
  color: #392338;
  cursor: pointer;
}

.circle li .placeholder .button img {
  max-width: 40px;
}

.circle li:nth-child(1) {
  transform: rotate(0deg) skewY(-54deg);
}

.circle li:nth-child(2) {
  transform: rotate(36deg) skewY(-54deg);
}

.circle li:nth-child(3) {
  transform: rotate(72deg) skewY(-54deg);
}

.circle li:nth-child(4) {
  transform: rotate(108deg) skewY(-54deg);
}

.circle li:nth-child(5) {
  transform: rotate(144deg) skewY(-54deg);
}

.circle li:nth-child(6) {
  transform: rotate(180deg) skewY(-54deg);
}

.circle li:nth-child(7) {
  transform: rotate(216deg) skewY(-54deg);
}

.circle li:nth-child(8) {
  transform: rotate(252deg) skewY(-54deg);
}

.circle li:nth-child(9) {
  transform: rotate(288deg) skewY(-54deg);
}

.circle li:nth-child(10) {
  transform: rotate(324deg) skewY(-54deg);
}

#circular-menu-active {
  position: fixed;
  top: -99999px;
  left: -99999px;
}

#circular-menu-active:checked~label .circular-menu-listings {
  right: -200px;
  transform: rotate(62deg) scale(1);
  transition: transform 1s;
}

#circular-menu-active:checked~label .circular-menu-toggle {
  /* background-color: #392338; */
  transition: all 1s;
}

#circular-menu-active:checked~label .hamburger {
  border-color: transparent;
  transition: border-color 0.333333333333333s;
}

#circular-menu-active:checked~label .hamburger:after {
  top: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(45deg);
  transition-property: top, transform;
  transition-duration: 0.25s, 1s;
}

#circular-menu-active:checked~label .hamburger:before {
  bottom: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(-45deg);
  transition-property: bottom, transform;
  transition-duration: 0.25s, 1s;
}

#circular-menu-active:checked~label .button:hover {
  color: #c1264e;
}

#circular-menu-active:checked~label .circular-menu-arrow {
  visibility: visible;
  transition: all 1s 1.111111111111111s;
}

#circular-menu-active:checked~label .circular-menu-arrow-top label {
  display: none;
}

#circular-menu-active:checked~label .circular-menu-arrow-top label[for="degree-up-0"] {
  display: block;
}

#circular-menu-active:checked~label #degree-up-0:checked~.circular-menu-listings {
  transform: rotate(-46deg);
}

#circular-menu-active:checked~label #degree-up-0:checked~.circular-menu-arrow-top label {
  display: none;
}

#circular-menu-active:checked~label #degree-up-0:checked~.circular-menu-arrow-top label[for="degree-up-1"] {
  display: block;
}

#circular-menu-active:checked~label #degree-up-1:checked~.circular-menu-listings {
  transform: rotate(62deg);
}

#circular-menu-active:checked~label #degree-up-1:checked~.circular-menu-listings~.circular-menu-arrow-top label {
  display: none;
}

#circular-menu-active:checked~label #degree-up-1:checked~.circular-menu-listings~.circular-menu-arrow-top label[for="degree-up-0"] {
  display: block;
}

#menu {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 10px;
  top: 0;
  list-style: none;
  font-size: 200%;
}

#menu-right {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 10px;
  top: 0;
  list-style: none;
  font-size: 200%;
}

#menu-right:not(:target)>a:first-of-type,
#menu-right:target>a:last-of-type {
  opacity: 1;
  z-index: 2;
}

#menu-right:not(:target)>.icon-plus:before,
#menu-right:target>.icon-minus:before {
  opacity: 1;
}



#menu-right:not(:target)>.menu-item {
  opacity: 0;
}

#menu-right:target>.menu-item:nth-child(6) {
  transform: rotate(95deg) translateY(80px) rotate(-95deg);
  transition-delay: 0s;
}

#menu-right:target>.menu-item:nth-child(5) {
  transform: rotate(60deg) translateY(80px) rotate(-60deg);
  transition-delay: 0.1s;
}

#menu-right:target>.menu-item:nth-child(3) {
  transform: rotate(25deg) translateY(80px) rotate(-25deg);
  transition-delay: 0.2s;
}

#menu-right:target>.menu-item:nth-child(4) {
  transform: rotate(-10deg) translateY(80px) rotate(10deg);
  transition-delay: 0.3s;
}

.menu-button {
  opacity: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.5);
  background-size: 100%;
  border-radius: 50%;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
}

#menu-right .menu-button {
  right: 10px;
  left: auto;
}

#menu .menu-button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 6px;
}

#menu-right .menu-button svg {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
}

.menu-button:hover {
  color: #fff;
}

.menu-button svg {
  vertical-align: 0;
}

#menu:not(:target)>a:first-of-type,
#menu:target>a:last-of-type {
  opacity: 1;
  z-index: 3;
}

#menu:not(:target)>.icon-plus:before,
#menu:target>.icon-minus:before {
  opacity: 3;
}

.menu-item {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  line-height: 5px;
  top: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(0px, 0px);
  transition: transform 500ms;
  transition: 0.5s;
  z-index: 1;
}


#menu-right .menu-item {
  right: 10px;
  left: auto;
}

.menu-item:hover {
  opacity: 0.5;
  box-shadow: 0 5px 10px black;
}

.menu-item a {
  color: #fff;
  position: relative;
  top: 8%;
  left: 0;
  text-decoration: none;
}

.menu-item a img {
  width: 80%;
}

.menu-item a svg {
  color: #fff;
  width: 28px;
  height: 28px;
  margin-top: 2px;
}

#menu:not(:target)>.menu-item {
  opacity: 0;
}

#menu:target>.menu-item:nth-child(6) {
  transform: rotate(265deg) translateY(80px) rotate(94deg);
  transition-delay: 0s;
}

#menu:target>.menu-item:nth-child(5) {
  transform: rotate(300deg) translateY(80px) rotate(60deg);
  transition-delay: 0.1s;
}

#menu:target>.menu-item:nth-child(3) {
  transform: rotate(335deg) translateY(80px) rotate(30deg);
  transition-delay: 0.2s;
}

#menu:target>.menu-item:nth-child(4) {
  transform: rotate(10deg) translateY(80px) rotate(-10deg);
  transition-delay: 0.3s;
}

.content {
  position: absolute;
  text-align: center;
  margin: -10px 0 0 -30px;
  top: 70%;
  left: 50%;
  font-size: 20px;
}

/* END Circular Menu */
@media only screen and (max-device-width: 425px) {
  .avatar-menu .nav-tabs {
    /* padding-left: 20px; */
    padding-right: 0;
    /* overflow: scroll; */
    /* flex-wrap: nowrap; */
  }

  .avatar-part-row .tab-content {
    max-height: 300px;
    overflow: auto;
  }

  #menu:target>.menu-item:nth-child(6) {
    transform: rotate(265deg) translateY(80px) rotate(94deg);
    transition-delay: 0s;
  }

  #menu:target>.menu-item:nth-child(5) {
    transform: rotate(300deg) translateY(80px) rotate(60deg);
    transition-delay: 0.1s;
  }

  #menu:target>.menu-item:nth-child(3) {
    transform: rotate(335deg) translateY(80px) rotate(30deg);
    transition-delay: 0.2s;
  }

  #menu:target>.menu-item:nth-child(4) {
    transform: rotate(10deg) translateY(80px) rotate(-10deg);
    transition-delay: 0.3s;
  }

  .mini-profile .card {
    width: 14rem !important;
  }

  .mini-profile .card .card-body {
    padding: 10px;
  }

  .mini-profile .player-name {
    font-size: 12px;
  }

  .camera-mode-base {
    display: none;
  }

  .avatar-menu .nav-tabs .nav-item a svg {
    max-width: 30px;
  }

  .explore-carousel-featured .carousel-control-prev {
    display: none;
  }

  .explore-carousel-featured .carousel-control-next {
    display: none;
  }

  .btn-group-interact button {
    width: 40px;
    height: 40px;
    padding: 7px;
  }

  .chat-box {
    left: 5px !important;
    right: 5px !important;
  }
}

@media only screen and (max-device-height: 425px) {
  .chat-box .chat-card-body {
    height: 200px;
  }
}

.required-user-data {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #000;
  color: #fff;
}

.required-user-data button {
  margin: 15px 15px;
}

.loading-bar-bg {
  width: 100%;
  max-width: 400px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative;
  z-index: 1;
  /* margin: 0 auto; */
}

.progress-bar-loading {
  /* position: absolute; */
  /* bottom: 0; */
  height: 10px;
  /* max-width: 400px; */
  border-radius: 5px;
  /* margin: 0 auto; */
  margin-top: -10px;
  position: relative;
  background-color: #fff !important;
  z-index: 2;
}

.loading-text-section {
  margin-top: 5% !important;
}

.loading-text-section h5 {
  font-weight: 500;
  font-size: 26px;
}

.loading-bar-section {
  flex-direction: column;
  /* margin-top: 100px !important; */
  position: absolute;
  width: 100%;
  bottom: 10%;
}

.loading-bar-message {
  font-size: 18px;
  margin-bottom: 12px;
}

.loading-bar-trademark {
  font-size: 15px;
  margin-top: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-bar-trademark img {
  margin-left: 5px;
}

.text-default {
  color: #ff2d55;
}

/* Explore section */
.explore-carousel-featured .carousel-caption {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  text-align: left;
}

.explore-img-section {
  background-color: #000;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 150px;
  overflow: hidden;
}

.explore-img-section img {
  object-fit: contain;
}

.explore-carousel-featured .carousel-indicators {
  margin-bottom: -20px;
}

.explore-carousel-featured .carousel-control-prev {
  width: auto;
  left: -15px;
}

.explore-carousel-featured .carousel-control-next {
  width: auto;
  right: -15px;
}

.scrollable {
  overflow: auto;
}

.circular-profile {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  color: #fff;
}

.circular-profile svg {
  color: #fff;
}

.minimap-modal-btn {
  position: absolute;
  top: 10px;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.minimap-modal-btn svg {
  color: #fff;
}

/* Color Picker Adjustment */
.rcp {
  padding: 0 !important;
}

/* ENDLESS RUN GAME */

.endlessrun-box {
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  z-index: 2000;
}

.endlessrun-box .card {
  border: none;
  min-width: 350px;
  background: none;
}

.endlessrun-box .card .card-header {
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  padding: 0.65rem 1rem;
  position: relative;
  font-weight: 600;
  color: #ffffff;
}

.endlessrun-box .card .card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.5);
}

.endlessrun-box .card .card-body {
  color: #FFF;
  background: rgba(0, 0, 0, 0.9);
}


.endlessrun-box.countdown {
  top: 20%;
}


.endlessrun-box.coin {
  top: 10%;
}

.modal-backdrop {
  z-index: 2000;
}

.modal-account-settings {
  z-index: 2001 !important;
}

.treasure-event-button {
  position: absolute;
  top: 140px;
  width: 100px;
  right: 0;
  left: 0;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  z-index: 1;
}

.btn.btn-transparent {

  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  /* border-radius: 10%; */
}

.modal-transparent {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-transparent .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.modal-transparent .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}


.modal-transparent .modal-body {
  background-color: #fff;
  padding: auto;
  margin: 1rem;
  border-radius: 3px;
  margin-top: 10px;
}


.modal-transparent.black-body .modal-body {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.treasure-hunt.daily-login-section {
  text-align: center;
}

.sub-part-section {
  transition: 0.1s ease-in;
}

.btn-default-dark {
  border-radius: 23px !important;
  background-color: #000 !important;
  color: white !important;
}

.btn-default-dark-outline {
  border-radius: 23px !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.btn-default-transparent-white {
  border-radius: 23px !important;
  background-color: transparent;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.btn-default-transparent-black {
  border-radius: 23px !important;
  background-color: transparent;
  color: #000 !important;
  border: 1px solid transparent !important;
}

.fade-transition {
  transition: 0.5s ease-in;
}

.modal {
  z-index: 999999999 !important;
}

@media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  /* your CSS to target only landscape mobile users */
}

.button-group-vote {
  left: 10px;
  position: absolute;
  top: 15%;
  z-index: 1;
}

.hair-color-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 5px 10px 10px 5px
}

.hair-color-scroll button {
  display: inline-block;
  padding: 0;
}

/* UT SECTION */
.ut-loading-color {
  background-color: #ffd500 !important;
}

.welcome-page-ut {
  z-index: 9999999;
}

.modal-content {
  z-index: 9999999;
}

.welcome-page-ut .image-background {
  min-height: 150px;
  height: 10%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}

.welcome-page-ut .logo-section {

  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  text-align: center;
}


.welcome-page-ut .text-section {
  text-align: center;
  padding: 36px 15px 15px 15px;
}

.logo-bar-section {
  position: absolute;
  top: 0;
  height: 100%;
}


/* DG Section */

.welcome-page-dg .image-background {
  min-height: 150px;
  height: 10%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}

.welcome-page-dg .modal-body {
  /* background-image: url('../../public/img/loading/dg/bg-welcome-page.png'); */
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  /* color: #fff; */

}


.welcome-page-dg .logo-section {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  text-align: center;
}

.welcome-page-dg .logo-section .logo {
  width: 100px;
}

.welcome-page-dg .text-section {
  text-align: center;
  padding: 60px 15px 36px 15px;
}


.treasure-hunt-icon-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.treasure-hunt-icon-modal svg {
  width: 50px;
}

.treasure-hunt-icon-modal svg.icon-center {
  width: 36px;
  margin: 5px;
}

.treasure-hunt-modal {
  border-radius: 0px;
}

.treasure-hunt-modal .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.treasure-hunt-modal .modal-body {
  padding-top: 0;
}

.cover-vote-list {
  display: flex;
  justify-content: center;
}

.cover-vote-list .card {
  height: 100%;
}

.cover-vote-list .card .card-body {
  padding-bottom: 0;
}

.cover-vote-list .card .card-footer {
  border-top: none;
}

.cover-vote-list .divider-vote {

  margin-top: 10px;
  margin-bottom: 10px;
}

#css3d,
#webgl {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.open-chat {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;

}

.tutorial {
  font-size: 16px;
  color: #fff;
}

.modal-body-tutorial {
  z-index: 99999999999;
}

.tutorial svg {
  max-width: 30px;
  max-height: 30px;
}

.tutorial .modal-body .row {}

.tutorial .modal-body .col-md-6 {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
}

.tutorial .modal-body .col-md-4 {
  align-self: center;
}

#player-list-table.table-hover>tbody>tr:hover>* {
  color: #fff;
}

.list-audio-near-cover {
  position: absolute;
  top: 80px;
  background-color: #000;
  padding: 10px;
  left: 50%;
  margin: 0 auto;
  border-radius: 10px;

  max-width: 50%;
  overflow-x: scroll;
  overflow: hidden;
  transform: translateX(-50%);
}

.list-audio-near-cover .player-near-audio {
  width: 50px;
  padding: 0;
  float: left;
  margin: 0 10px;
}


.list-audio-near-cover .player-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.list-audio-near-cover .player-near-audio .avatar {
  width: 100%;
  border-radius: 50%;
  padding: 10px;
  background-color: #eee;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
}

.list-audio-near-cover .player-near-audio .avatar.speaking {
  border: 2px solid #5cb85c;
}

.list-audio-near-cover .player-near-audio .player-name {
  color: #fff;
  font-size: 11px;
  margin-top: 5px;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.list-audio-near-cover .player-near-audio .mic-indicator {
  position: absolute;
  right: -5px;
  bottom: -2px;
  width: 24px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  color: #fff;
}


.list-audio-near-cover .player-near-audio .mic-indicator.enable {
  background-color: #5cb85c;
}

.list-audio-near-cover .player-near-audio .mic-indicator.disable {
  background-color: #d9534f;
}

.btn-participant {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  padding: 5px !important;
}

.btn-participant .badge {
  position: absolute !important;
  top: -10px !important;
  right: -5px;

}

.participant-list {
  overflow-y: auto;
}

.participant-list .player-list {
  /* padding-bottom: 10px !important; */
}

.participant-list hr {
  margin: 10px !important;
}

.participant-list .avatar {
  width: 50px;
  border-radius: 50%;
  padding: 10px;
  background-color: #eee;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
  /* float: left; */
  margin: 0 auto;

}

.participant-list .player-name {
  align-self: center;
}

.white-icon {
  fill: white;
}

.player-name-3d {
  background-color: rgba(32, 32, 32, 0.438);
  color: white;
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.player-speak-indicator {
  position: absolute;
  border: solid;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  border-width: 2px;
  border-color: rgb(255, 201, 64);

}

.player-speak-icon {
  background-color: rgb(255, 201, 64);
  border-radius: 50%;

  width: 20px;
  height: 20px;
  position: absolute;
  top: -7px;
  left: -12px;
}

.chat-box-3d {
  background-color: white;
  padding: 20px;
  min-width: 150px;
  max-width: 260px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.chat-box-arrow {
  background-color: rgb(255, 255, 255);
  width: 20px;
  height: 20px;

  position: absolute;
  bottom: -5px;

  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.menu-button-close {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0px;
}