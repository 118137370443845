.private-chat-base{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.473);
    z-index: 100000000;
}

.private-chat{
    width: 90%;
    max-width: 500px;
    height: 90%;
    max-height: 700px;
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    border: solid;
    border-width: 5px;
    border-radius: 15px;

    background-color: black;
}

.private-chat-close{
    position: absolute;
    top: 10px;
    right: 20px;

    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.404);
    border-width: 1px;
    border-color: 1px;
    border-color: white;

    color: white;
    font-size: 20px;
}