.drop-npc-item{
    position: absolute;
    top: 160px;
    left: 50%;

    padding: 20px;
    background-color:rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    color: white;
    border-radius: 15px;
    font-weight: bolder;
    font-size: 20px;
}

.drop-npc-item-title{
    font-size: 15px;
}