.trivia-popup-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background-color: rgba(0, 0, 0, 0.719);
}

.trivia-popup{
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;

    border-radius: 20px;

    background-color: white;

    transform: translate(-50%,-50%)  scale(1);
    animation: bounce 1s linear;
}

.trivia-popup-answer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1);
    font-size: 200px;
    
}

@keyframes bounce{
    0%{
        transform: translate(-50%,-50%) scale(0);
    }
    10%{
        transform: translate(-50%,-50%) scale(1.1);
    }
    20%{
        transform: translate(-50%,-50%) scale(0.95);
    }
    30%{
        transform: translate(-50%,-50%) scale(1);
    }
    100%{
        transform: translate(-50%,-50%) scale(1);
    }
}

.popup-wrong{
    color: red;
}

.popup-right{
    color: rgb(0, 238, 0);
}