.player-name-input-base{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    
}

.player-name-input{
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.player-name-button{
    display: inline-block;
    margin-top: 10px;
}

.player-name-label{
    font-size: 12px;
}