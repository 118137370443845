.player-duet-animation{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
}

.player-duet-request{
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 0px 10px 10px 10px;
    width: 150px;
}

.player-duet-request-button{
    border: none;
    border-radius: 10px;
    width: 100%;
    background-color: rgb(233, 233, 233);
    display: inline-block;
    margin-bottom: 5px;
}

.player-duet-request-button:hover{
    background-color: rgb(212, 212, 212);
}

.player-duet-reply{
    position: absolute;
    top: 70%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    width: 300px;
    height: 100px;
    transform: translate(-50%, -50%);
}

.player-duet-reply-button-yes{
    position: absolute;
    bottom: 15px;
    left: 48%;
    transform: translateX(-100px);
    border: none;
    border-radius: 15px;
    width: 100px;
    background-color: rgb(226, 226, 226);
    padding: 5px 10px 5px 10px;
}

.player-duet-reply-button-yes:hover{
    background-color: rgb(199, 199, 199);
}

.player-duet-reply-button-no:hover{
    background-color: rgb(199, 199, 199);
}

.player-duet-reply-button-no{
    position: absolute;
    bottom: 15px;
    left: 52%;
    transform: translateX(0%);
    border: none;
    border-radius: 15px;
    width: 100px;
    background-color: rgb(226, 226, 226);
    padding: 5px 10px 5px 10px;
}

.player-duet-header{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 40%;
    border-radius: 10px;

    font-weight: bold;
}

.player-duet-timer{
    position: absolute;
    bottom: 5px;
    right: 10px;
    background-color: greenyellow;
    border-radius: 50%;
    font-weight: bold;
    width: 20px;
    height: 20px;
    padding-bottom: 2px;
    text-align: center;
}

.player-duet-reject{
    position: absolute;
    bottom: 10%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.267);
    border-radius: 10px;
    width: 300px;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-weight: bold;
    font-size: small;
}