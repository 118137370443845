.ping{
    /* position: absolute; */
    /* top: 120px; */
    /* left: 50%; */

    color: rgb(160, 1, 1);
    /* background-color: white; */
    /* padding: 5px; */
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    /* border-radius: 10px; */

    font-size: 13px;
    font-weight: bold;
    opacity: 0.5;

    /* transform: translateX(-50%); */
}