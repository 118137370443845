.npc-pop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translateY(30%); */
  z-index: 999;
}
.npc-pop-body {
  background: rgba(14, 5, 49, 0.5);
  border-radius: 5px;

  max-width: 800px;
  width: 100%;
  justify-content: center;
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  
  position: absolute;
  
  bottom: 100px;
  /* transform: translateY(-100px); */
}

@media screen and (max-width: 600px){
  .npc-pop-body {
    bottom: 0px;
  }
}

.npc-pop-panel {
  background-color: #0E0531;
  width: 100%;

  /* max-width: 500px; */
  min-height: 100px;

  left: 50%;
  bottom: 200px;
  font-size: 20px;

  /* position: absolute; */
  color: white;

  padding: 15px;
  
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.button-popup {
  /* position: absolute; */
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 10px;
  color: black;
  bottom: 100px;
}

.skip {
  left: 50%;

  background-color: rgb(255, 126, 126);
  transform: translateX(3px);
}

.next {
  right: 50%;

  background-color: rgb(166, 255, 115);
  transform: translateX(-3px);
}

.npc-button-body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.npc-button-body button {
    margin:0 10px;
    color: white;
}

.npc-input{
  text-align: center;
}