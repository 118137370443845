.social-media-popup{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.7);
}

.social-media-panel{
    
    position: absolute;

    top: 50%;
    left: 50%;

    height: 80%;
    object-fit: contain;

    display: flex;

    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translate(-50%, -50%);
    
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 15px;
}

.social-media-photo{
    border-radius: 15px;
}

.button-base{
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;
    display: inline-block;

    vertical-align: bottom;
}

.social-button{
    position: absolute;
    margin-right: 5px;
    width: 6vh;
    height: 6vh;
    background-color: rgba(0, 0, 0, 0.822);
    color:white;

    border-color: white;
    border: solid;
    border-width: 1px;
    border-radius: 50%;

    font-size: 2vh;
}

.social-button-go{
    right: 1vh;
    bottom: 1vh;
}

.social-button:hover{
    background-color: rgba(0, 0, 0, 0.904);
}

.social-button-1{
    left: 1vh;
    bottom: 1vh;
}

.social-button-2{
    left: 1vh;
    bottom: 8.5vh;
}

.social-button-3{
    left: 1vh;
    bottom: 16vh;
}

.social-button-4{
    left: 1vh;
    bottom: 23.5vh;
}

.social-button-5{
    left: 1vh;
    bottom: 31vh;
}

.social-button-6{
    left: 1vh;
    bottom: 38.5vh;
}

.social-button-7{
    left: 1vh;
    bottom: 46vh;
}

.social-button-8{
    left: 1vh;
    bottom: 53.5vh;
}

.social-button-9{
    left: 1vh;
    bottom: 61vh;
}

.social-icon{
    color: white;
    width: 100%;
    height: 100T;
}

.social-button-close{
    right: 0px;
    top: 0px;
    transform: translate(50%, -50%);
}

.social-icon-svg{
    width: 4vh;
}

.bannerModal {
    max-height: calc(80vh + 8px);
    max-width: calc(45vh + 8px);
  }
  
  .bannerModal .modal-content {
    height: calc(80vh + 8px);
    width: calc(45vh + 8px);
    padding: 4px;
    background: #363636;
  }
  
  .bannerContainer .cyberpunkModalClose {
    top: -10px;
    right: -10px;
    z-index: 0;
  }
  
  .bannerImage {
    max-height: 100%;
  }
  
  .bannerActionLeft {
    position: absolute;
    left: 15px;
    bottom: -15px;
  }
  
  .bannerActionRight {
    position: absolute;
    right: 15px;
    bottom: -15px;
  }
  
  .bannerActionMiddle {
    position: absolute;
    right: 15px;
    bottom: 50%;
  }

  .social-icon-base{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }