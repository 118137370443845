.emoji-animation-base{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;
    
}

#emoji-animation-toggle{
    position: absolute;
    bottom: 70px;
    right: 20px;
    border: none;
    height: 70px;
    width: 70px;

    pointer-events: all;

    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    color: rgb(190, 190, 190);
}

.emoji-animation-button-base{
    position: absolute;
    bottom: 150px;
    right: 20px;
    width: 150px;
    background-color: rgb(224, 224, 224);
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
}

.emoji-animation-button{
    /* display: inline-block; */
    width: 100%;
    height: 100%;
    padding: 5px;
    /* margin-bottom: 5px; */
    
    pointer-events: all;
    border-radius: 10px;
    border: none;
    background-color: rgb(255, 255, 255, 0.8);
}

.emoji-animation-button svg{
    max-width: 60px;
    width: 100%;
    height: auto;

}
.emoji-animation-button span{
    display: block;
}

.emoji-animation-button:hover{
    background-color: rgb(221, 215, 215, 0.8);
}

#emoji-animation-toggle:hover{
    background-color: rgb(221, 215, 215, 0.3);
}

.emoji-card-body .col-3 {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
}