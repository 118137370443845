.camera-mode-base{
    position: absolute;
    left: 50%;
    top: 10px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 10px;

    transform: translate(-50%, 0%) scale(0.9);
}

.camera-mode-title{
    font-style: bold;
    font-size: 10px;
    padding-bottom: 5px;
    display: inline-block;

}

.camera-mode-word-sub{
    font-size: 11px;
    width: 100%;
    text-align: center;
    
    display: inline-block;
}