.audio-user-list-panel {
    width: 200px;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.575);
    position: absolute;
    top: 75px;
    right: 20px;
    border-radius: 20px;
}

.audio-user-list-title {
    color: white;
    background-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;

    border-radius: 20px 20px 0px 0px;
}

.audio-user-list-close {
    position: absolute;
    right: 10px;
    border-radius: 50%;
    top: 7px;
    color: white;
    border: none;

    background-color: black;
}

.audio-user-list-close:hover {
    background-color: rgb(58, 58, 58);
}

.audio-player-list-player-panel {
    position: relative;
    color: white;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.audio-player-list-player-panel:hover {
    background-color: rgba(255, 255, 255, 0.164);
}

.audio-user-list-nearby {
    color: white;
    font-size: 12px;
    height: 20px;
    background-color: black;
    margin-top: 2px;
}

.audio-user-list-nearby-title {
    text-align: left;
    position: absolute;
    padding-left: 10px;
    width: 100%;
}

.audio-user-list-nearby-count {
    text-align: right;
    position: absolute;
    padding-right: 10px;
    width: 100%;
}

.audio-player-list-player-name {
    text-align: right;
    padding-right: 10px;
}

.audio-player-list-nearby-icon {
    position: absolute;
    left: 10px;
}

.audio-player-list-volume-icon {
    left: 30px;
    position: absolute;
}

.audio-user-list {
    height: 330px;
    overflow: auto;
}


::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(145, 145, 145);
    border-radius: 10px;
}