.hover-board-base {
    position: absolute;
    left: 20px;
    top: 180px;
}

.hover-board-button {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
}


.hover-board-button:hover {
    background-color: #cccccc;
}