.count-down-base {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    z-index: 9000;
}

.count-down {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;

    color: white;
    font-weight: bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: black;

    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
}