.change-server-room-base{
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    /* pointer-events: none; */

    background-color: rgba(0, 0, 0, 0.514);
    z-index: 1000;
}

.change-server-room-panel{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 270px;
    height: 160px;

    background-color: white;

    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.671);

    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 15px;


    color: white;
}

.change-server-room-btn{
    position: absolute;
    top:45%;
    transform: translateY(-50%);

    width: 40px;
    height: 30px;
    border: none;
    border-radius: 15px;
}

.change-server-room-act{
    position: absolute;
    top:70%;
    transform: translateY(-50%X);

    width: 100px;
    height: 35px;
    border: none;
    border-radius: 15px;
}

.change-server-room-btn-cancel{
    left: 53%;
    transform: translateY(-50%, 50%);
}
.change-server-room-btn-save{
    right: 53%;
    transform: translateY(-50%, -50%);
}

.change-server-room-act:hover{
    
    background-color: rgb(192, 192, 192);
}

.change-server-room-btn:hover{
    background-color: rgb(192, 192, 192);
}

.change-server-room-btn-left{
    left: 50px;
}

.change-server-room-btn-right{
    right: 50px;
}

.change-server-room-btn-save{

}

.change-server-room-background{
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 45%;
    left: 50%;
    width: 60px;
    height: 60px;
    color: black;

    border-radius: 15px;

    transform: translate(-50%, -50%);
}

.change-room{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: bold;
}