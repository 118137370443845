.day-night-base {
       /* position: absolute; */
       top: 140px;
       right: 20px;
       text-align: left;
       background-color: white;
       padding: 10px;
       border-radius: 10px;
}

.day-night-label {
       line-height: 10px;
       margin-bottom: 5px;
       /* font-size: 12px; */

}

.day-night-auto-base {
       top: 10px;
       right: 30px;
       /* font-size: 12px; */
}

.day-night-auto-label {
       padding-right: 5px;
       display: inline;
}

.day-night-auto-check {
       /* position: absolute; */
       padding-top: 10px;
       /* top: 50%; */

       /* transform: translateY(-50%); */
}

.slider2 {
       width: 100%;
       height: 15px;
       -webkit-appearance: none;
       background: #111;
       outline: none;
       border-radius: 15px;
       overflow: hidden;
       box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}

.slider2::-webkit-slider-thumb {
       -webkit-appearance: none;
       width: 15px;
       height: 15px;
       border-radius: 50%;
       background: #ff2d55 ;
       cursor: pointer;
       border: 4px solid #333;
       box-shadow: -407px 0 0 400px #ff2d55 ;
}

.slider2::-moz-range-thumb {
       -webkit-appearance: none;
       width: 15px;
       height: 15px;
       border-radius: 50%;
       background: #ff2d55 ;
       cursor: pointer;
       border: 4px solid #333;
       box-shadow: -407px 0 0 400px #ff2d55 ;
}