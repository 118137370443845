.monitor-base{
    position: absolute;
    right: 20px;
    top: 150px;
    text-align: left;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    opacity: 0.5;
}
