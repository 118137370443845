.capture-photo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.315);

    color: #fff;
    background-color: #5c636a;
    border: none;

    padding: 10px;
    width: 50px;
    height: 50px;
    font-size: 14px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50% !important;
    pointer-events: all;
}

.capture-button-base {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);

    width: 80px;
    height: 80px;

    border: solid;
    border-color: white;
    border-width: 5px;
    border-radius: 50%;


}

.capture-button {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    height: 90%;

    border: none;
    border-radius: 50%;

    transform: translate(-50%, -50%);

    pointer-events: all;
}

.capture-button:hover {
    background-color: rgb(221, 221, 221);

}

.capture-frame {
    position: absolute;
    width: 95%;
    height: auto;
    max-width: 600px;

    top: 50%;
    left: 50%;

    opacity: 0.8;
    transform: translate(-50%, -50%);
}

.photo-result {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.911);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.image-base {
    
    position: absolute;
    width: 95%;
    padding-top: 100%;
    position: relative;
    
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
}

.image-result {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    width: 95%;
    max-width: 600px;
    transform: translate(-50%, -50%);
}

.image-result-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    width: 95%;
    max-width: 600px;
    transform: translate(-50%, 0);
}


.button-download {
    /* position: absolute; */

    /* transform: translate(-110%, -50%); */
    pointer-events: all;
}

.button-try-again{
    position: absolute;

    transform: translate(10%, -50%);
    pointer-events: all;
}

.btn-res {
    position: absolute;
    /* padding: 10px;
    width: 40%;
    max-width: 200px;
    margin-top: 20px;
    border-radius: 50px;

    border: solid;
    font-size: small; */
}