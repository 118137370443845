.landscape-warning-base{
    position: absolute;

    z-index: 999999;

    width: 100%;
    height: 100%;

    background-color: black;

    top: 0px;
    left: 0px;

    font-family: "Magistros", Fallback, sans-serif;
    color: white;
}

@media only screen and (orientation:landscape) {
    .landscape-warning-base { display:block; }
  }
   
  /* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
  @media only screen and (orientation:portrait) {
    .landscape-warning-base { display:none; }
  }

  .warning-text{
    position: absolute;
    left: 50%;
    top:50%;

    transform: translate(-50%, -50%);
  }